<template>
  <LostPassword :cardHeading="$t('Reset my password')">
    <i18n path="lostPassword.message" class="lost-password__message text-secondary text-sm" tag="p">
      <span class="fw-bold">{{ mail }}</span>
    </i18n>

  </LostPassword>
</template>

<script>
import LostPassword from "../components/LostPassword/LostPassword.vue";

export default {
  components: { LostPassword },
  data() {
    return {};
  },
  computed: {
    mail() {
      return this.$route.query.email;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>