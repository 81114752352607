<template>
  <div class="container d-flex align-items-center lost-password">
    <div class="row">
      <div class="col-md-4 offset-md-4 col-sm-12">
        <Card class="p-5">
          <div class="row">
            <div class="col-md-12">
              <h1 class="">{{ cardHeading }}</h1>
            </div>
          </div>
          <slot></slot>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '../general/Card.vue';
export default {
  components: { Card },
  props: {
    cardHeading: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.lost-password {
  height: calc(100vh - 80px);
}
</style>